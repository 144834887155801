<template>
  <div id="books_info" v-if="infosData">
    <div class="title" style="margin-left:2rem">
      <p class="aqt_lang_mn titlefont">{{ $t('m.xiangi')|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconshuming"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.mingcheng')|U2M }} :{{ infosData.name_lang|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconISBN"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.isbnhao')|U2M }}:{{ infosData.isbn|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconchubanshe"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.atour')|U2M }} :{{ infosData.press|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconzuozhe_"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.cbs')|U2M }} : {{ infosData.author|U2M }}</p>
    </div>

    <div class="aqt_zhengzhuang">
      <i class="iconfont iconchubanshijian"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.cbtime')|U2M }} : {{ infosData.publish_date|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconpage"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.page')|U2M }}: {{ infosData.page|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconzishu"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.totlezs')|U2M }}: {{ infosData.word_count|U2M }}</p>
    </div>
    <div class="aqt_zhengzhuang">
      <i class="iconfont iconjiage"></i>
      <p class="aqt_lang_mn contentfont">{{ $t('m.jiage')|U2M }}: {{ infosData.price }}</p>
    </div>
    <div class="title">
      <p class="aqt_lang_mn titlefont">{{ $t('m.jianjie')|U2M }} </p>
    </div>
    <div class="aqt_zhengzhuang">
      <p class="aqt_lang_mn contentfont" v-html="infosData.content_lang?U2M(infosData.content_lang):''"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {}
  },

  watch: {
    'data': function (val) {
      const _this = this
      _this.infosData.price = _this.data.price
      _this.infosData.press = _this.data.press_name_lang ? (_this.data.press_name_lang.mn ? _this.data.press_name_lang.mn : _this.data.press_name_lang.zh) : ''
      _this.infosData.author = _this.data.author_lang ? (_this.data.author_lang.mn ? _this.data.author_lang.mn : _this.data.author_lang.zh) : ''
      _this.infosData.isbn = _this.data.isbn ? _this.data.isbn : ''
      _this.infosData.publish_date = _this.data.publish_date_chu ? _this.data.publish_date_chu : ''
      _this.infosData.word_count = _this.data.word_count ? _this.data.word_count : ''
      _this.infosData.page = _this.data.page ? _this.data.page : ''
      if (_this.data.name_lang && _this.data.name_lang.mn) {
        _this.infosData.name_lang = _this.data.name_lang.mn
      } else if (_this.data.name_lang && _this.data.name_lang.zh) {
        _this.infosData.name_lang = _this.data.name_lang.zh
      } else {
        _this.infosData.name_lang = ''
      }
      _this.infosData.content_lang = _this.data.content_lang ? (_this.data.content_lang.mn ? _this.data.content_lang.mn : '') : ''
    }
  },
  data() {
    return {
      infosData: {
        publish_date: '',
        word_count: "",
        isbn: '',
        author: '',
        name_lang: '',
        press: '',
        content_lang: '',
        price: '',
      },
      // data1: { //获取书籍数据
      //   book_id: '',
      //   member_id: JSON.parse(localStorage.getItem('userInfoMemberId')).member_id?JSON.parse(localStorage.getItem('userInfoMemberId')).member_id:'',
      // },
      type: '2' //电子书
    }

  },
  methods: {
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {

      const _this = this
      setTimeout(() => {
        if (_this.data) {
          _this.infosData.price = _this.data.price
          _this.infosData.press = _this.data.press_name_lang ? (_this.data.press_name_lang.mn ? _this.data.press_name_lang.mn : _this.data.press_name_lang.zh) : ''
          _this.infosData.author = _this.data.author_lang ? (_this.data.author_lang.mn ? _this.data.author_lang.mn : _this.data.author_lang.zh) : ''
          _this.infosData.isbn = _this.data.isbn ? _this.data.isbn : ''
          _this.infosData.publish_date = _this.data.publish_date_chu ? _this.data.publish_date_chu : ''
          _this.infosData.word_count = _this.data.word_count ? _this.data.word_count : ''
          if (_this.data.name_lang && _this.data.name_lang.mn) {
            _this.infosData.name_lang = _this.data.name_lang.mn
          } else if (_this.data.name_lang && _this.data.name_lang.zh) {
            _this.infosData.name_lang = _this.data.name_lang.zh
          } else {
            _this.infosData.name_lang = ''
          }
          _this.infosData.content_lang = _this.data.content_lang ? (_this.data.content_lang.mn ? _this.data.content_lang.mn : '') : ''

          // _this.infosData.content_lang = _this.data.content_lang.mn ? _this.data.content_lang.mn :_this.data.content_lang.zh?_this.data.content_lang.zh: ''
        }
      })
    },
  },
  created() {
  },
  mounted() {
    this.infoData()
    console.log(this.infosData, 'infosData')
    console.log(this.data, 'data')

  },
}
</script>

<style lang="less">
#books_info {
  display: flex;
  overflow: auto !important;

  .title {
    width: 3.4rem;
    height: calc(100% - 1rem);
    color: var(--themeColor);
    font-size: 2rem;
    margin: 0;

    p {
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 auto;
      height: 100%;
    }
  }

  .aqt_zhengzhuang {
    //height: calc(100% - 3.4rem);
    height: calc(100% - 1rem);
    width: 3rem;
    color: #333;
    font-size: 1.2rem;
    margin: 0 .3rem;

    i {
      color: var(--themeColor);
      display: block;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    // white-space:nowrap;
    // -o-text-overflow:ellipsis;
    // text-overflow:ellipsis;
    // overflow: hidden;
    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.8rem;
      word-wrap: break-word;
      word-break: normal;
      //height: calc(100vh - 400px);
      max-height: 90%;
      color: #888888;
    }
  }
}
</style>
